import { request } from "./network";

// 获取已解决数据监控
export function getAllEvent(params) {
    return request({
        method: "get",
        url: "/eventProblem/getAllEvent",
        params,
    });
}

// 获取异常事件筛选数据
export function getRoleAndGroupData(params) {
    return request({
        method: "get",
        url: "/eventProblem/getRoleAndGroupData",
        params,
    });
}
