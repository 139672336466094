<template>
    <el-container>
        <el-main class="main-box">
            <!-- card面板 -->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <!-- 头部标题与form表单 -->
                    <el-row :gutter="20">
                        <!-- span属性指的是占据多少列 -->
                        <el-col :span="6" style="padding: 0;">
                            <span>异常事件列表</span>
                        </el-col>
                    </el-row>
                </div>
                <div class="text item">
                    <el-row :gutter="20">
                        <el-input
                                class="search"
                                v-model="queryParams.studentName"
                                placeholder="请输入学生姓名"
                        >
                            <i slot="suffix" class="el-input__icon el-icon-search" @click="onHandle"></i>
                        </el-input>
                        <el-select v-model="queryParams.subjectUuid" class="search" placeholder="科目" clearable
                                   @change="onHandle">
                            <el-option
                                    v-for="(item,index) in subjectMap"
                                    :key="index"
                                    :label="item.subjectName"
                                    :value="item.subjectUuid"
                            >
                            </el-option>
                        </el-select>
                        <el-select v-model="queryParams.groupName" class="search" placeholder="分组" clearable
                                   @change="onHandle">
                            <el-option
                                    v-for="(item,index) in groupNames"
                                    :key="index"
                                    :label="item.group_name"
                                    :value="item.group_id"
                            >
                            </el-option>
                        </el-select>
                        <el-select v-model="queryParams.eventStatus" class="search" placeholder="状态" clearable
                                   @change="onHandle">
                            <el-option
                                    v-for="(item,index) in statusMap"
                                    :key="index"
                                    :label="item.statusDisceribe"
                                    :value="item.eventStatus"
                            >
                            </el-option>
                        </el-select>
                    </el-row>
                    <el-table
                            stripe
                            :data="tableList"
                    >
                        <el-table-column prop="id" label="事件编号" align="center" width="70"/>
                        <el-table-column prop="studentName" label="学生姓名" align="center"/>
                        <el-table-column prop="idCard" label="身份证号" align="center"/>
                        <el-table-column prop="guardianPhone" label="紧急联系人手机号" align="center"/>
                        <el-table-column prop="subjectName" label="科目名称" align="center"/>
                        <el-table-column prop="teacherName" label="监考人员" align="center"/>
                        <el-table-column prop="teacherPhone" label="监考手机号" align="center"/>
                        <el-table-column prop="groupName" label="监控组" align="center"/>
                        <el-table-column prop="categoryName" label="事件类型" align="center"/>
                        <el-table-column prop="eventProblemDescribe" label="详细描述" align="center"/>
                        <el-table-column prop="eventStatus" label="事件状态" align="center">
                            <template slot-scope="scope">
                                <span :class="scope.row.eventStatus=='挂起'?'color-1':''">{{scope.row.eventStatus}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" align="center">
                            <template slot-scope="scope">
                                {{formatHandle(scope.row.createTime)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="processTime" label="领取时间" align="center">
                            <template slot-scope="scope">
                                {{formatHandle(scope.row.processTime)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="solverTime" label="已解决时间" align="center">
                            <template slot-scope="scope">
                                {{formatHandle(scope.row.solverTime)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="createToProcess" label="创建-已解决（时长）" align="center"/>
                        <el-table-column prop="processToSolver" label="领取-已解决（时长）" align="center"/>
                        <el-table-column prop="solverMobile" label="处理人" align="center"/>
                        <el-table-column prop="mobileGroupName" label="所属组别" align="center"/>
                        <el-table-column prop="eventStatus" label="事件状态" align="center">
                            <template slot-scope="scope">
                                <el-button
                                        v-if="scope.row.eventStatus=='挂起'"
                                        size="small"
                                        type="text"
                                        style="color: #563279"
                                        @click="disposeHandle(scope.row)"
                                >解决
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页器 -->
                    <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :page-size="queryParams.pageSize"
                            :page-sizes="[10, 20, 30, 40]"
                            :current-page="queryParams.pageNum"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
    import {updateEventProblem} from "r/jiDong/eventHall";
    import {getAllEvent, getRoleAndGroupData} from "r/jiDong/alreadySolved.js";
    import {formatTime} from "common/utils";

    export default {
        name: "AlreadySolved",
        data() {
            return {
                isSelect: true,// true-待处理 false-已处理
                tableList: [],
                teacherId: null,
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    studentName: null,
                    subjectUuid: null,
                    eventStatus: null,
                    groupName: null,
                },
                statusMap: [],
                groupNames: [],
                subjectMap: [],
                total: 0
            }
        },
        created() {
            let teacherId = window.localStorage.getItem('jiDong-teacherId');
            this.teacherId = teacherId;
            this.getRoleAndGroupList();
            this.getList();
        },
        methods: {
            // 解决
            disposeHandle(row) {
                this.$confirm('确认解决?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {};
                    data.id = row.id;
                    data.status = 3;
                    data.teacherIdSolver = this.teacherId;
                    updateEventProblem(data)
                        .then(res => {
                            if (res.data.code != 200) {
                                return this.$message.error(res.data.message);
                            }
                            this.$message.success(res.data.message);
                            this.getList();
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            formatHandle(val) {
                if (val) {
                    return formatTime(val);
                }
                return '-';
            },
            onHandle() {
                this.queryParams.pageNum = 1;
                this.getList();
            },
            // 获取异常事件筛选数据
            getRoleAndGroupList() {
                getRoleAndGroupData({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                        this.groupNames = res.data.data.groupNames;
                        this.subjectMap = res.data.data.subjectMap;
                        this.statusMap = res.data.data.statusMap;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getList() {
                getAllEvent(this.queryParams)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            return this.$message.error(res.data.message);
                        }
                        this.tableList = res.data.data.records;
                        this.total = res.data.data.total;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 控制每页显示几条数据
            handleSizeChange(newSize) {
                this.queryParams.pageSize = newSize;
                this.getList();
            },
            // 控制显示第几页
            handleCurrentChange(newNum) {
                this.queryParams.pageNum = newNum;
                this.getList();
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "a/scss/jiDongCommon";
    @import "a/scss/table";
    @import "a/scss/page";

    ::v-deep .el-table__header-wrapper {
        height: auto !important;
    }

    .color-1 {
        color: #F56C6C;
    }
</style>

